// src/lib/i18n/index.ts
import { init, register, addMessages } from 'svelte-i18n';
import ko from './locales/ko.json';

export const defaultLocale = 'ko';
export const availableLocales = ['ko', 'en'];

addMessages('ko', ko); // 디폴트 언어셋은 sync로 불러오지 않으면 간헐적으로 로딩이 늦어서 랜더링중에 에러가 발생
// register('ko', () => import('./locales/ko.json'));
register('en', () => import('./locales/en.json'));

init({
    fallbackLocale: defaultLocale,
    initialLocale: defaultLocale,
});
